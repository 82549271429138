import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const BlogPostCard = ({ data }) => {
  const date = new Date(Date.parse(data.frontmatter.date))
  const month = date.toLocaleString("default", { month: "short" })
  const day = date.toLocaleString("default", { day: "2-digit" })
  const year = date.toLocaleString("default", { year: "numeric" })
  return (
    <Link to={data.frontmatter.slug}>
      <article className="blog-post-card">
        {data.frontmatter.featuredImage ? (
          <Img
            fluid={data.frontmatter.featuredImage.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt={data.frontmatter.title + " - Featured image"}
            imgStyle={{}}
            className="featured-image"
          />
        ) : (
          ""
        )}
        <div className="post-content">
          <h2 className="title">{data.frontmatter.title}</h2>
          <p className="desc">{data.frontmatter.desc}</p>
        </div>

        <time dateTime={data.frontmatter.date}>
          <span className="month">{month}</span>
          <span className="day">{day}</span>
          <span className="year">{year}</span>
        </time>
      </article>
    </Link>
  )
}

export default BlogPostCard
